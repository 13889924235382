@font-face {
    font-family: 'Uni Sans';
    src: url('../fonts/UniSansLightItalic.woff2') format('woff2'),
        url('../fonts/UniSansLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Sans';
    src: url('../fonts/UniSansLight.woff2') format('woff2'),
        url('../fonts/UniSansLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Sans';
    src: url('../fonts/UniSansRegular.woff2') format('woff2'),
        url('../fonts/UniSansRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Sans';
    src: url('../fonts/UniSansRegularItalic.woff2') format('woff2'),
        url('../fonts/UniSansRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'uw-bothell';
    src:  url('../fonts/uw-bothell.eot?mqj3fu');
    src:  url('../fonts/uw-bothell.eot?mqj3fu#iefix') format('embedded-opentype'),
      url('../fonts/uw-bothell.woff2?mqj3fu') format('woff2'),
      url('../fonts/uw-bothell.ttf?mqj3fu') format('truetype'),
      url('../fonts/uw-bothell.woff?mqj3fu') format('woff'),
      url('../fonts/uw-bothell.svg?mqj3fu#uw-bothell') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'uw-bothell' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-errorPage:before {
    content: "\e903";
  }
  .icon-comment:before {
    content: "\73";
  }
  .icon-lightbulb:before {
    content: "\73";
  }
  .icon-accessibility:before {
    content: "\41";
  }
  .icon-slideForm:before {
    content: "\2d";
  }
  .icon-certificate:before {
    content: "\72";
  }
  .icon-pause:before {
    content: "\25";
  }
  .icon-check:before {
    content: "\61";
  }
  .icon-slideThisOrThatQuiz:before {
    content: "\30";
  }
  .icon-external-link:before {
    content: "\6e";
  }
  .icon-media:before {
    content: "\70";
  }
  .icon-lock:before {
    content: "\63";
  }
  .icon-enlarge:before {
    content: "\23";
  }
  .icon-minus:before {
    content: "\40";
  }
  .icon-plus:before {
    content: "\5e";
  }
  .icon-x:before {
    content: "\62";
  }
  .icon-maintenance:before {
    content: "\7e";
  }
  .icon-learn-more:before {
    content: "\68";
  }
  .icon-slideItemsOfInterest:before {
    content: "\68";
  }
  .icon-slideQuiz:before {
    content: "\32";
  }
  .icon-slideCopy:before {
    content: "\60";
  }
  .icon-slideChecklist:before {
    content: "\38";
  }
  .icon-link:before {
    content: "\64";
  }
  .icon-add-student:before {
    content: "\26";
  }
  .icon-search:before {
    content: "\66";
  }
  .icon-printer:before {
    content: "\2a";
  }
  .icon-faq:before {
    content: "\78";
  }
  .icon-slideMasterTest:before {
    content: "\37";
  }
  .icon-slideVideo:before {
    content: "\31";
  }
  .icon-account:before {
    content: "\28";
  }
  .icon-select-account:before {
    content: "\6b";
  }
  .icon-announce:before {
    content: "\65";
  }
  .icon-achievement:before {
    content: "\71";
  }
  .icon-glossary:before {
    content: "\76";
  }
  .icon-home:before {
    content: "\67";
  }
  .icon-slideview:before {
    content: "\69";
  }
  .icon-slideReveal:before {
    content: "\29";
  }
  .icon-slideSelfDirected:before {
    content: "\5f";
  }
  .icon-student:before {
    content: "\43";
  }
  .icon-quickview:before {
    content: "\6a";
  }
  .icon-error:before {
    content: "\2b";
  }
  .icon-arrow-next:before {
    content: "\e901";
  }
  .icon-arrow-previous:before {
    content: "\e902";
  }
  .icon-arrow-down:before {
    content: "\77";
  }
  .icon-logout:before {
    content: "\21";
  }
  .icon-pdf:before {
    content: "\7b";
  }
  .icon-slideSurvey:before {
    content: "\36";
  }
  .icon-slideMatchingGame:before {
    content: "\34";
  }
  .icon-play:before {
    content: "\24";
  }
  .icon-menu:before {
    content: "\6f";
  }
  .icon-mail:before {
    content: "\e900";
  }
  .icon-slideWordSearch:before {
    content: "\35";
  }
  