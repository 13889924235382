@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.buffer {
  overflow: auto;
  min-height: calc(100vh - 40px);
}

footer {
  position: relative;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background: #000;

  .adgLogoWrap {
    a {
      display: block;
      background: #000 !important;
    }

    .adgLogo {
      height: 30px;
      width: auto;
    }
  }
}

.socialMediaTitle {
  margin-bottom: 15px;
  font-weight: 400;
}

.socialMediaLogins {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  .facebookLogin {
    @include button(#1877f2, #1877f2);
  }
  .googleLogin {
    @include button(#ebebec, #ebebec);
  }
  .twitterLogin {
    @include button(#14171a, #14171a);
    .text {
      @include screenReader;
    }
  }
  > a {
    text-align: center;
    padding: 1em 3em !important;

    .svgIcon {
      width: 25px;
      height: auto;
      margin-right: 10px;
    }
    .text {
      font-weight: bold;
      font-size: 1.1em;
    }
  }
}

//These style is just used for the Honeypot feature. These styles keep the field from being seen by humans and they should not be changed or removed.
.fieldWrapper.studentName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

// END BASE STYLES //

header {
  position: relative;
  z-index: 120;

  display: flex;
  height: $headerHeight;

  .branding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: 18px 30px 18px 30px;

    h1 {
      margin: 0;
    }
  }

  .logoWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 300px;
    height: 100%;
  }

  .logo {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .orientationTitle {
    line-height: 1.1em;
    font-weight: 400;
    color: $primary;
  }
}

#membership {
  position: relative;
  z-index: 5;
  padding-bottom: 120px;

  .titleArea {
    position: relative;
    width: 100%;
    min-height: 200px;
    padding: 80px 20px 80px;
    border-radius: $radius;
    text-align: center;
    font-size: 1.68em;
    @include bgWithContrast($primary);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      // background: linear-gradient(105deg, #e8e3d3 14.29%, transparent 14.29%, transparent 50%, #e8e3d3 50%, #e8e3d3 64.29%, transparent 64.29%, transparent 100%);
      // background-size: 7.25px 27.05px;
      background: repeating-linear-gradient(110deg, transparent, transparent 6px, #e8e3d3 6px, #e8e3d3 8px);
    }


    &.accountProfiler {
      font-size: 1.5em;
    }

    h2 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 900;
    }

    p {
      margin: 0;
    }
  }

  .errorWrap {
    position: relative;
    top: -45px;
    z-index: 5;
    width: 800px;
    max-width: calc(100% - 20px);
    margin: 10px auto 20px;
  }

  .errorMessage {
    text-align: center;
    background: darken($primary, 10%);
    box-shadow: $shadow;
    color: $light;
    border-radius: 10px;
    font-weight: 600;
    padding: 5px 10px;
  }

  .loginNav {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid $lightGrey;
    padding-top: 10px;
    text-align: center;
    font-size: 0.9em;

    > * {
      margin: 0 5px;
    }
  }

  .formWrap {
    position: relative;
    z-index: 5;
    top: -45px;
    width: 800px;
    max-width: calc(100% - 30px);
    margin: 10px auto 20px;
    padding: 25px 40px;
    border-radius: $radius;
    box-shadow: $shadow;
    text-align: center;
    @include bgWithContrast($light);

    input[type="radio"] {
      @include radio;
    }

    input[type="checkbox"] {
      @include checkbox;
    }

    input[type="checkbox"] ~ label,
    input[type="radio"] ~ label {
      padding-left: 0.35em;
    }
  }

  .formWrap.login {
    .horzDivider {
      display: flex;
      align-items: center;
      margin: 25px 0;

      .line {
        height: 2px;
        flex-grow: 1;
        background: $lightGrey;
      }

      .or {
        text-transform: uppercase;
        color: $basic;
        margin-inline: 15px;
      }
    }

    .loginWrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 8px;
    }

    .returningWrapper {
      width: calc(60% - 20px);
      text-align: center;
    }

    .newAccountWrapper {
      width: 40%;

      > a:first-child {
        @include button;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &[data-type="Student"] {
          .icon:before {
            text-transform: lowercase;
            content: "z";
          }
        }

        .icon {
          font-size: 2.5em;
          margin-bottom: 10px;
        }

        .text {
          font-weight: bold;
          font-size: 1.15em;
        }
      }
    }
  }

  .formWrap.landingPage {
    padding-top: 40px;
    padding-bottom: 40px;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      @include button;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 6px;
      font-size: 1.4em;

      @include hover {
        .icon {
          border-radius: 50%;
          @include bgWithContrast($primary);
        }
      }
    }

    .text {
      font-weight: bold;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 80px;
      margin-bottom: 15px;
      font-family: $iconFont;
      font-size: 2.1em;
      border: 2px solid $light;
      border-radius: 50%;
      transition: all $veryFast;
      @include bgWithContrast($secondary);
    }

    .guestLoginBtn {
      transform-origin: 25% 50%;
      .icon:before {
        content: "k";
      }
    }

    .studentLoginBtn {
      transform-origin: 75% 50%;
      .icon:before {
        content: "z";
      }
    }
  }

  .formWrap.accountProfiler {
    text-align: left;

    .instruction {
      margin-bottom: 15px;
      text-align: center;
    }

    select {
      margin: 0 0 0 5px;
      border: 5px solid $lightGrey;
      background: $lightGrey;
      font-weight: 700;

      @include hover {
        cursor: pointer;
      }
    }

    .buttonHolder {
      margin: 20px 0 20px;
    }

    .resetAccountTypeBtn {
      border: 0;
      padding: 0;
      background: none;
      border-radius: 0;
      overflow: visible;
      font-size: 0.8em;
      border-bottom: 1px solid $primary;
      cursor: pointer;
      @include colorWithContrast($primary);
      transition: $veryFast;

      @include hoverAndFocus {
        border-color: $secondary;
        @include colorWithContrast($secondary);
      }
    }
  }

  .buttonHolder {
    text-align: center;
  }

  button {
    @include button;
    margin: 0 auto;
  }

  .styledFieldset {
    margin-bottom: 20px;

    .groupLegend {
      margin-bottom: 10px;
    }

    .controlWrapper {
      select {
        padding-right: 30px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }

      > span {
        &.dropDownArrow {
          display: inline;
          position: relative;
          height: 30px;
          width: 30px;
          left: -25px;
          pointer-events: none;

          @include hover {
            cursor: pointer;
          }

          &:after {
            content: "w";
            @include vertCenter;
            font-family: $iconFont;
            font-size: 1em;
            color: $basic;
          }
        }
      }
    }
  }
}

.simpleDialog {
  .closeDialog {
    @include button;

    .icon {
      display: none;
    }
  }
}

#resetData {
  button {
    @include button;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $sm) {
  header {
    height: auto;
    .branding {
      flex-direction: column;
      .logoWrap {
        justify-content: center;
        width: 100%;
        max-width: 280px;
        flex: auto;
        margin-bottom: 10px;
      }
      .orientationTitle {
        text-align: center;
      }
    }
  }
  .login > .socialMediaLogins {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  #membership {
    .titleArea {
      font-size: 1.5em;
    }
    .formWrap {
      &.login {
        .loginWrapper {
          flex-direction: column;
          gap: 15px;
        }
        .newAccountWrapper {
          width: auto;
          margin: auto;
        }
        .returningWrapper {
          width: auto;
        }
      }
      &.landingPage {
        .buttons {
          a {
            flex-grow: 1;
          }
        }
      }
    }
  }
}

@media (max-width: $xs) {
  #membership {
    .titleArea {
      font-size: 1.35em;
      padding: 75px 20px 70px;
      min-height: auto;
    }
    .formWrap {
      padding: 25px;
    }
    .formWrap.login .newAccountWrapper {
      width: auto;
    }
    .formWrap.landingPage {
      .buttons {
        flex-direction: column;
        a {
          width: 100%;
          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  #resetData {
    button {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: $xxs) {
  #membership .titleArea.accountProfiler {
    font-size: 1.125em;
  }
  header {
    .orientationTitle {
      font-size: 1.8em;
    }
  }
}

@media (max-width: $tiny) {
  #membership .formWrap {
    padding: 16px;
  }
  #membership {
    .titleArea {
      padding: 70px 15px 65px;
      font-size: 1.1em;
    }
    .formWrap.landingPage {
      padding: 20px;
    }
  }
}
