@use "mixins" as *;
@use "variables" as *;

#membership .formWrap.maintenance {
  .formArea {
    display: flex;
    align-items: center;
    flex-direction: column;

    .icon {
      color: $primary;
    }
    .rte {
      h3.heading {
        &:before {
          left: 50%;
          transform: translateX(-50%);
        }
        &:after {
          display: none;
        }
      }
    }
  }
  // background: $secondary;
  // border-color: $light;

  // .formArea {
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: center;
  //   width: 100%;
  //   background: transparent;

  //   .rte {
  //     color: $light;
  //     padding-right: 25px;
  //   }

  //   .icon {
  //     flex-shrink: 0;
  //     flex-grow: 0;
  //     width: 350px;
  //     height: 350px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     font-size: 16em;
  //     border-left: 2px solid lighten($secondary, 30%);
  //     color: $light;
  //   }
  // }
}

// @media (max-width: $sm) {
//   #membership .formWrap.maintenance {
//     .formArea {
//       .icon {
//         width: 30%;
//         height: 150px;
//         font-size: 6em;
//       }
//     }
//   }
// }

// @media (max-width: $xs) {
//   #membership .formWrap.maintenance {
//     .formArea {
//       flex-direction: column-reverse;
//       justify-content: center;

//       .icon {
//         width: 75px;
//         height: 75px;
//         font-size: 3em;
//         padding: 0;
//         margin-bottom: 25px;
//         border-left: 0;
//         border-bottom: 2px solid lighten($secondary, 30%);
//       }
//     }
//   }
// }
