@use "variables" as *;
@use "mixins" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

details {
  // If you change this transition speed, you'll have to change it at the top of Accordion.ts
  transition: all $medium;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

.accordionModule {
  .accordionTitle {
    cursor: pointer;
    list-style: none;
  }
}

// END BASE STYLES //

.accordionModule {
  position: relative;
  margin: 10px 0;

  &[open] {
    > .accordionTitle {
      width: 100%;
      &:before {
        left: 40px;
        opacity: 0;
      }
      &:after {
        left: calc(100% - 40px);
        opacity: 0;
      }
      .icon {
        transform: translateY(-50%) rotate(180deg);
        transform-origin: center;
      }
    }
  }

  .accordionTitle {
    position: relative;
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 20px 20px 40px;
    @include bgWithContrast($primary);
    transition: all $veryFast;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: calc(100% - 1px);
      background: $primary;
      transform: skewX(10deg);
      transform-origin: 50% 100%;
      transition: all $veryFast;
    }

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      width: 20px;
      height: 100%;
      background: $primary;
      transform: skewX(-10deg);
      transform-origin: 50% 0%;
      transition: all $veryFast;
    }

    @include hoverAndFocus {
      @include bgWithContrast($accent);

      &:before, &:after {
        background: $accent;
      }

      .icon {
        color: contrast($accent);
      }
    }

    .icon {
      &:before {
        content: "\77";
        font-family: $iconFont;
      }
      color: contrast($primary);
      position: absolute;
      top: 50%;
      left: 18px;
      transform: rotate(0) translateY(-50%);
      transform-origin: 4px 50%;
      transition: 0.2s transform;
    }
  }

  .accordionWrapper {
    padding: 20px 20px;
    @include bgWithContrast($light);
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
