@use "mixins" as *;
@use "variables" as *;

.membershipPage .errorPage {
  .errorWrap {
    background: $light;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
    margin-top: 10px;
    box-shadow: $shadow;
    padding: 30px;
  }

  .icon {
    padding: 30px;
    font-size: 6em;
    border-bottom: 2px solid $primary;
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .rte {
    text-align: center;
    padding: 40px 30px 20px 30px;
    width: 50%;
    h3.heading {
      background: transparent;
      color: contrast($light);
      text-transform: none;
      text-align: left;
    }
  }

  @media (max-width: $sm) {
    .errorWrap {
      padding: 20px 30px;
    }
    .icon {
      width: 300px;
    }
    .rte {
      width: 100%;
    }
  }
  @media (max-width: $tiny) {
    .errorWrap {
      padding: 20px;
    }
    .icon {
      width: 100%;
    }
    .rte {
      padding: 30px 0 20px;
    }
  }
}