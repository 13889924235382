@font-face {
  font-family: "Uni Sans";
  src: url("../fonts/UniSansLightItalic.woff2") format("woff2"), url("../fonts/UniSansLightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Uni Sans";
  src: url("../fonts/UniSansLight.woff2") format("woff2"), url("../fonts/UniSansLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Uni Sans";
  src: url("../fonts/UniSansRegular.woff2") format("woff2"), url("../fonts/UniSansRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Uni Sans";
  src: url("../fonts/UniSansRegularItalic.woff2") format("woff2"), url("../fonts/UniSansRegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "uw-bothell";
  src: url("../fonts/uw-bothell.eot?mqj3fu");
  src: url("../fonts/uw-bothell.eot?mqj3fu#iefix") format("embedded-opentype"), url("../fonts/uw-bothell.woff2?mqj3fu") format("woff2"), url("../fonts/uw-bothell.ttf?mqj3fu") format("truetype"), url("../fonts/uw-bothell.woff?mqj3fu") format("woff"), url("../fonts/uw-bothell.svg?mqj3fu#uw-bothell") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "uw-bothell" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-errorPage:before {
  content: "\e903";
}

.icon-comment:before {
  content: "s";
}

.icon-lightbulb:before {
  content: "s";
}

.icon-accessibility:before {
  content: "A";
}

.icon-slideForm:before {
  content: "-";
}

.icon-certificate:before {
  content: "r";
}

.icon-pause:before {
  content: "%";
}

.icon-check:before {
  content: "a";
}

.icon-slideThisOrThatQuiz:before {
  content: "0";
}

.icon-external-link:before {
  content: "n";
}

.icon-media:before {
  content: "p";
}

.icon-lock:before {
  content: "c";
}

.icon-enlarge:before {
  content: "#";
}

.icon-minus:before {
  content: "@";
}

.icon-plus:before {
  content: "^";
}

.icon-x:before {
  content: "b";
}

.icon-maintenance:before {
  content: "~";
}

.icon-learn-more:before {
  content: "h";
}

.icon-slideItemsOfInterest:before {
  content: "h";
}

.icon-slideQuiz:before {
  content: "2";
}

.icon-slideCopy:before {
  content: "`";
}

.icon-slideChecklist:before {
  content: "8";
}

.icon-link:before {
  content: "d";
}

.icon-add-student:before {
  content: "&";
}

.icon-search:before {
  content: "f";
}

.icon-printer:before {
  content: "*";
}

.icon-faq:before {
  content: "x";
}

.icon-slideMasterTest:before {
  content: "7";
}

.icon-slideVideo:before {
  content: "1";
}

.icon-account:before {
  content: "(";
}

.icon-select-account:before {
  content: "k";
}

.icon-announce:before {
  content: "e";
}

.icon-achievement:before {
  content: "q";
}

.icon-glossary:before {
  content: "v";
}

.icon-home:before {
  content: "g";
}

.icon-slideview:before {
  content: "i";
}

.icon-slideReveal:before {
  content: ")";
}

.icon-slideSelfDirected:before {
  content: "_";
}

.icon-student:before {
  content: "C";
}

.icon-quickview:before {
  content: "j";
}

.icon-error:before {
  content: "+";
}

.icon-arrow-next:before {
  content: "\e901";
}

.icon-arrow-previous:before {
  content: "\e902";
}

.icon-arrow-down:before {
  content: "w";
}

.icon-logout:before {
  content: "!";
}

.icon-pdf:before {
  content: "{";
}

.icon-slideSurvey:before {
  content: "6";
}

.icon-slideMatchingGame:before {
  content: "4";
}

.icon-play:before {
  content: "$";
}

.icon-menu:before {
  content: "o";
}

.icon-mail:before {
  content: "\e900";
}

.icon-slideWordSearch:before {
  content: "5";
}

/*---TEXT AND FONT---*/
/*---BACKGROUND---*/
/*---POSITIONING---*/
/*---CONTENT---*/
/*---LISTS---*/
/*---BORDER ARROWS---*/
/**Forms**/
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible, *.focus-visible {
  outline-offset: 2px !important;
  outline: 4px solid #b7a57a;
}

body[data-reduce-animation=true] *,
body[data-reduce-animation=true] *::before,
body[data-reduce-animation=true] *::after {
  animation-duration: 0.01ms !important;
  animation-iteration-count: 1 !important;
  transition-duration: 0.01ms !important;
  scroll-behavior: auto !important;
}

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  scrollbar-color: #85754d #f5f5f5;
}
html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
html::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  background-color: #f5f5f5;
}
html::-webkit-scrollbar-thumb {
  background-color: #85754d;
}

html[data-active-dialog]:not([data-active-dialog=""]) {
  overflow: hidden;
}

html[data-active-zone=slides] {
  overflow-y: scroll;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.1;
  font-size: 16px;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0.35em 0 0.75em;
  line-height: 1.55em;
}

ol,
ul {
  text-align: left;
  margin: 0.4em 0 0.75em;
}

li {
  line-height: 1.25em;
}

iframe {
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid #ebebec;
}

a {
  color: #4b2e83;
  background-color: white;
  transition: 200ms;
}
@media (hover: hover) and (any-pointer: fine) and (hover: hover) and (any-pointer: fine) {
  a:hover:hover {
    background-color: #4b2e83;
    color: white;
  }
}
@media (hover: hover) and (any-pointer: fine) {
  [data-browser=ie] a:hover:hover {
    background-color: #4b2e83;
    color: white;
  }
}
@media (hover: hover) and (any-pointer: fine) {
  .ie a:hover:hover {
    background-color: #4b2e83;
    color: white;
  }
}
@media (hover: hover) and (any-pointer: fine) {
  a:hover.focus-visible {
    background-color: #4b2e83;
    color: white;
  }
}
@media (hover: hover) and (any-pointer: fine) {
  a:hover:focus-visible {
    background-color: #4b2e83;
    color: white;
  }
}
@media (hover: hover) and (any-pointer: fine) {
  [data-browser=ie] a:hover:hover {
    background-color: #4b2e83;
    color: white;
  }
}
[data-browser=ie] [data-browser=ie] a:hover:hover {
  background-color: #4b2e83;
  color: white;
}
.ie [data-browser=ie] a:hover:hover {
  background-color: #4b2e83;
  color: white;
}
[data-browser=ie] a:hover.focus-visible {
  background-color: #4b2e83;
  color: white;
}
[data-browser=ie] a:hover:focus-visible {
  background-color: #4b2e83;
  color: white;
}
@media (hover: hover) and (any-pointer: fine) {
  .ie a:hover:hover {
    background-color: #4b2e83;
    color: white;
  }
}
[data-browser=ie] .ie a:hover:hover {
  background-color: #4b2e83;
  color: white;
}
.ie .ie a:hover:hover {
  background-color: #4b2e83;
  color: white;
}
.ie a:hover.focus-visible {
  background-color: #4b2e83;
  color: white;
}
.ie a:hover:focus-visible {
  background-color: #4b2e83;
  color: white;
}
@media (hover: hover) and (any-pointer: fine) {
  a.focus-visible:hover {
    background-color: #4b2e83;
    color: white;
  }
}
[data-browser=ie] a.focus-visible:hover {
  background-color: #4b2e83;
  color: white;
}
.ie a.focus-visible:hover {
  background-color: #4b2e83;
  color: white;
}
a.focus-visible.focus-visible {
  background-color: #4b2e83;
  color: white;
}
a.focus-visible:focus-visible {
  background-color: #4b2e83;
  color: white;
}
@media (hover: hover) and (any-pointer: fine) {
  a:focus-visible:hover {
    background-color: #4b2e83;
    color: white;
  }
}
[data-browser=ie] a:focus-visible:hover {
  background-color: #4b2e83;
  color: white;
}
.ie a:focus-visible:hover {
  background-color: #4b2e83;
  color: white;
}
a:focus-visible.focus-visible {
  background-color: #4b2e83;
  color: white;
}
a:focus-visible:focus-visible {
  background-color: #4b2e83;
  color: white;
}
a .targetBlankNotifier {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media (max-width: 800px) {
  iframe {
    border: none;
  }
}
willis-zone {
  display: block;
}

[inert] {
  overflow: hidden;
  pointer-events: none;
}

willis-dialog:not([data-open=true]) {
  display: none !important;
}

.dialogBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slide .dialogBackdrop {
  height: 100%;
}

.dialogInner {
  position: relative;
}

[data-dialog-style=modal] {
  display: flex;
  align-items: center;
  overflow-y: auto;
  scrollbar-color: #444444 #f5f5f5;
  scrollbar-width: thin;
}
[data-dialog-style=modal]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
[data-dialog-style=modal]::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
[data-dialog-style=modal]::-webkit-scrollbar-thumb {
  background-color: #444444;
}
[data-dialog-style=modal] div[role=dialog] {
  width: 580px;
  margin: auto;
}
[data-dialog-style=modal] div[role=dialog].reportNeeded {
  width: 80%;
  max-width: 1000px;
}

[data-dialog-style=largeModal] {
  display: flex;
  align-items: center;
  overflow-y: auto;
  scrollbar-color: #444444 #f5f5f5;
  scrollbar-width: thin;
}
[data-dialog-style=largeModal]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
[data-dialog-style=largeModal]::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
[data-dialog-style=largeModal]::-webkit-scrollbar-thumb {
  background-color: #444444;
}
[data-dialog-style=largeModal] div[role=dialog] {
  width: 800px;
  max-width: 100vw;
  margin: auto;
}

[data-dialog-style=shade] {
  overflow-y: scroll;
}
[data-dialog-style=shade] div[role=dialog] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: #4b2e83;
  color: white;
}
[data-dialog-style=shade] div[role=dialog] .dialogLabel {
  max-width: 800px;
}
[data-dialog-style=shade] div[role=dialog] .dialogContent {
  max-width: 800px;
}
[data-dialog-style=shade] div[role=dialog] .dialogActions {
  max-width: 800px;
}

[data-dialog-style^=sidebar] {
  display: flex;
  align-items: stretch;
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] {
  justify-content: flex-start;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarRight] {
  justify-content: flex-end;
}
[data-dialog-style^=sidebar] div[role=dialog] {
  width: 440px;
}

[data-dialog-style^=full] {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}
[data-dialog-style^=full] div[role=dialog] {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  [data-dialog-style^=sidebar] div[role=dialog] {
    width: 100%;
    min-width: 0;
  }
  [data-dialog-style=modal] div[role=dialog] {
    width: 100%;
  }
}
div[data-dialog-animation-speed=glacial] {
  animation-duration: 3000ms;
}

div[data-dialog-animation-speed=verySlow] {
  animation-duration: 1000ms;
}

div[data-dialog-animation-speed=slow] {
  animation-duration: 800ms;
}

div[data-dialog-animation-speed=medium] {
  animation-duration: 500ms;
}

div[data-dialog-animation-speed=fast] {
  animation-duration: 300ms;
}

div[data-dialog-animation-speed=veryFast] {
  animation-duration: 200ms;
}

.dialogBackdrop {
  animation-fill-mode: both;
  animation-duration: 200ms;
}
.dialogBackdrop.in {
  animation-name: fadeIn;
}
.dialogBackdrop.out {
  animation-name: fadeOut;
}

div[role=dialog] {
  position: relative;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
div[data-dialog-animation-name=fadeIn] {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
div[data-dialog-animation-name=fadeOut] {
  animation-name: fadeOut;
}

@keyframes fadeTopIn {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=fadeTopIn] {
  animation-name: fadeTopIn;
}

@keyframes fadeTopOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(-50%);
  }
}
div[data-dialog-animation-name=fadeTopOut] {
  animation-name: fadeTopOut;
}

@keyframes fadeBottomIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=fadeBottomIn] {
  animation-name: fadeBottomIn;
}

@keyframes fadeBottomOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(50%);
  }
}
div[data-dialog-animation-name=fadeBottomOut] {
  animation-name: fadeBottomOut;
}

@keyframes fadeRightIn {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=fadeRightIn] {
  animation-name: fadeRightIn;
}

@keyframes fadeRightOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}
div[data-dialog-animation-name=fadeRightOut] {
  animation-name: fadeRightOut;
}

@keyframes fadeLeftIn {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=fadeLeftIn] {
  animation-name: fadeLeftIn;
}

@keyframes fadeLeftOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}
div[data-dialog-animation-name=fadeLeftOut] {
  animation-name: fadeLeftOut;
}

@keyframes slideTopIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=slideTopIn] {
  animation-name: slideTopIn;
}
@media (max-width: 800px) {
  div[data-dialog-animation-name=slideTopIn] {
    animation-name: slideBottomIn;
  }
}

@keyframes slideTopOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
div[data-dialog-animation-name=slideTopOut] {
  animation-name: slideTopOut;
}
@media (max-width: 800px) {
  div[data-dialog-animation-name=slideTopOut] {
    animation-name: slideBottomOut;
  }
}

@keyframes slideBottomIn {
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=slideBottomIn] {
  animation-name: slideBottomIn;
}

@keyframes slideBottomOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(150%);
  }
}
div[data-dialog-animation-name=slideBottomOut] {
  animation-name: slideBottomOut;
}

@keyframes slideRightIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=slideRightIn] {
  animation-name: slideRightIn;
}
@media (max-width: 800px) {
  div[data-dialog-animation-name=slideRightIn] {
    animation-name: slideBottomIn;
  }
}

@keyframes slideRightOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
div[data-dialog-animation-name=slideRightOut] {
  animation-name: slideRightOut;
}
@media (max-width: 800px) {
  div[data-dialog-animation-name=slideRightOut] {
    animation-name: slideBottomOut;
  }
}

@keyframes slideLeftIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=slideLeftIn] {
  animation-name: slideLeftIn;
}

@keyframes slideLeftOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
div[data-dialog-animation-name=slideLeftOut] {
  animation-name: slideLeftOut;
}

.dialogBackdrop {
  background: rgba(235, 235, 236, 0.7);
}

.dialogContent {
  margin: 10px 0;
}

.dialogActions.generated button.closeDialog .icon:before {
  font-family: "uw-bothell";
  content: "b";
}

button.closeDialog .icon:before {
  font-family: "uw-bothell";
  content: "b";
}

.dialogLabel h2 {
  margin-bottom: 15px;
}

[data-dialog-style=modal] {
  padding: 20px;
}
.safari [data-dialog-style=modal] {
  padding: 20px 20px 150px;
}
[data-dialog-style=modal] .dialogInner {
  border-radius: 0;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  color: black;
  box-shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036), 0 29px 62px rgba(0, 0, 0, 0.05);
}
[data-dialog-style=modal] button.closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #4b2e83;
  color: white;
}
[data-dialog-style=modal] button.closeDialog:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  [data-dialog-style=modal] button.closeDialog:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #b7a57a;
    color: black;
    cursor: pointer;
  }
  [data-dialog-style=modal] button.closeDialog:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] [data-dialog-style=modal] button.closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-browser=ie] [data-dialog-style=modal] button.closeDialog:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie [data-dialog-style=modal] button.closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.ie [data-dialog-style=modal] button.closeDialog:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
[data-dialog-style=modal] button.closeDialog.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
[data-dialog-style=modal] button.closeDialog:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
[data-dialog-style=modal] button.closeDialog[disabled], [data-dialog-style=modal] button.closeDialog[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
[data-dialog-style=modal] button.closeDialog .icon {
  display: none;
}

.safari [data-dialog-style=largeModal] {
  padding: 20px 20px 150px;
}
[data-dialog-style=largeModal] .dialogInner {
  border-radius: 0;
  padding: 20px;
  background-color: #fff;
  color: black;
}
[data-dialog-style=largeModal] .dialogLabel {
  text-align: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #4b2e83;
  padding: 5px 10px 20px;
  text-transform: uppercase;
}
[data-dialog-style=largeModal] .dialogLabel h2 {
  margin: 0;
  padding: 0;
  font-size: 1.25em;
  color: #4b2e83;
}

[data-dialog-style=shade] .dialogInner {
  border-radius: 0 0 0 0;
  padding: 20px;
}

[data-dialog-style^=sidebar] .dialogInner {
  min-height: 100%;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] .dialogInner {
  border-radius: 0 0 0 0;
  padding: 20px;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarRight] .dialogInner {
  border-radius: 0 0 0 0;
  padding: 20px;
}

[data-dialog-style^=full] .dialogInner {
  padding: 20px;
}

.simpleDialog {
  background-color: #fff;
  color: black;
  border-radius: 0;
}

@media (max-width: 800px) {
  [data-dialog-style^=sidebar][data-dialog-style=sidebarRight] .dialogInner {
    border-radius: 0;
  }
  [data-dialog-style=modal] div[role=dialog] {
    width: 100%;
  }
  [data-dialog-style=largeModal] {
    align-items: flex-start;
  }
  [data-dialog-style=largeModal] div[role=dialog] {
    margin: 0;
  }
}
@media (max-width: 600px) {
  [data-dialog-style=modal] {
    display: block;
  }
  [data-dialog-style=modal] div[role=dialog].reportNeeded {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .safari [data-dialog-style=modal][data-backdrop-for=accessibilityPrefs] {
    padding: 20px 20px 150px;
  }
}
#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  display: none !important;
}
#background .bgImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
#background .bgImg[data-bg-pos="top left"] {
  background-position: 0% 0%;
}
#background .bgImg[data-bg-pos="top middle"] {
  background-position: 50% 0;
}
#background .bgImg[data-bg-pos="top right"] {
  background-position: 100% 0%;
}
#background .bgImg[data-bg-pos="center left"] {
  background-position: 0% 50%;
}
#background .bgImg[data-bg-pos=center] {
  background-position: 50% 50%;
}
#background .bgImg[data-bg-pos="center right"] {
  background-position: 100% 50%;
}
#background .bgImg[data-bg-pos="bottom left"] {
  background-position: 0% 100%;
}
#background .bgImg[data-bg-pos="bottom middle"] {
  background-position: 50% 100%;
}
#background .bgImg[data-bg-pos="bottom right"] {
  background-position: 100% 100%;
}
#background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #ebebec;
}
[data-is-loading=true] #background {
  opacity: 0;
}

.fieldWrapper.studentName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.longanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  position: relative;
  max-width: 500px;
}
.fieldWrapper label:not(.checkboxLabel),
.umbraco-forms-field.shortanswer label:not(.checkboxLabel),
.umbraco-forms-field.longanswer label:not(.checkboxLabel),
.umbraco-forms-field.date label:not(.checkboxLabel),
.umbraco-forms-field.password label:not(.checkboxLabel),
.umbraco-forms-field.dropdown label:not(.checkboxLabel) {
  line-height: 1;
  white-space: normal;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]),
.fieldWrapper select,
.fieldWrapper textarea,
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.shortanswer textarea,
.umbraco-forms-field.longanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.longanswer select,
.umbraco-forms-field.longanswer textarea,
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.date select,
.umbraco-forms-field.date textarea,
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.password select,
.umbraco-forms-field.password textarea,
.umbraco-forms-field.dropdown input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.dropdown select,
.umbraco-forms-field.dropdown textarea {
  appearance: none;
  position: relative;
  white-space: normal;
  line-height: 1;
  min-width: 100%;
  max-width: 100%;
  max-height: 120px;
}
.fieldWrapper select::-ms-expand,
.umbraco-forms-field.shortanswer select::-ms-expand,
.umbraco-forms-field.longanswer select::-ms-expand,
.umbraco-forms-field.date select::-ms-expand,
.umbraco-forms-field.password select::-ms-expand,
.umbraco-forms-field.dropdown select::-ms-expand {
  display: none;
}

.controlWrapper,
.adg-umbraco-forms-controlwrapper {
  border-bottom: 1px solid #ebebec;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.controlWrapper:last-child,
.adg-umbraco-forms-controlwrapper:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.controlWrapper > *:first-child,
.adg-umbraco-forms-controlwrapper > *:first-child {
  margin-top: 0 !important;
}
.controlWrapper > *:last-child,
.adg-umbraco-forms-controlwrapper > *:last-child {
  margin-bottom: 0 !important;
}
.controlWrapper .control,
.adg-umbraco-forms-controlwrapper .control {
  display: flex;
  align-items: center;
}
.controlWrapper label,
.adg-umbraco-forms-controlwrapper label {
  font-size: 1.2em;
  white-space: nowrap;
  cursor: pointer;
}
.controlWrapper .description,
.adg-umbraco-forms-controlwrapper .description {
  margin-top: 6px;
  font-size: 0.84em;
  color: #54545a;
}
.controlWrapper .description > *:first-child,
.adg-umbraco-forms-controlwrapper .description > *:first-child {
  margin-top: 0 !important;
}
.controlWrapper .description > *:last-child,
.adg-umbraco-forms-controlwrapper .description > *:last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper.studentName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.styledFieldset,
.umbraco-forms-fieldset {
  position: relative;
  border: 1px solid #ebebec;
  padding: 20px;
  background-color: rgba(235, 235, 236, 0.4);
  border-radius: 0;
}
.styledFieldset legend,
.styledFieldset .groupLegend,
.umbraco-forms-fieldset legend,
.umbraco-forms-fieldset .groupLegend {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 0;
  background-color: #4b2e83;
  color: white;
}
.styledFieldset .groupLegend,
.umbraco-forms-fieldset .groupLegend {
  display: inline-block;
  margin-bottom: 9px;
}
.styledFieldset .fieldsetGrid,
.umbraco-forms-fieldset .fieldsetGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.styledFieldset .fieldsetGrid .fieldWrapper,
.styledFieldset .fieldsetGrid .controlWrapper,
.umbraco-forms-fieldset .fieldsetGrid .fieldWrapper,
.umbraco-forms-fieldset .fieldsetGrid .controlWrapper {
  margin: 0;
}
.styledFieldset > :last-child,
.umbraco-forms-fieldset > :last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  margin: 10px auto;
}
.fieldWrapper label:not(.checkboxLabel),
.umbraco-forms-field.shortanswer label:not(.checkboxLabel),
.umbraco-forms-field.date label:not(.checkboxLabel),
.umbraco-forms-field.password label:not(.checkboxLabel),
.umbraco-forms-field.dropdown label:not(.checkboxLabel) {
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 2;
  display: block;
  margin: 0 0 5px;
  border: 0;
  padding: 0;
  font-size: 0.8em;
  font-family: "Encode Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #54545a;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]),
.fieldWrapper select,
.fieldWrapper textarea,
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.shortanswer textarea,
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.date select,
.umbraco-forms-field.date textarea,
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.password select,
.umbraco-forms-field.password textarea,
.umbraco-forms-field.dropdown input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.dropdown select,
.umbraco-forms-field.dropdown textarea {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 120px;
  min-height: 48px;
  margin: 0;
  border: 1px solid #a9a9ad;
  padding: 23px 8px 6px;
  border-radius: 0;
  background: #fff;
  color: #3f3f3f;
  text-align: left;
}
.fieldWrapper select,
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.date select,
.umbraco-forms-field.password select,
.umbraco-forms-field.dropdown select {
  padding-top: 24px;
  padding-right: 2.3em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) center;
  background-size: 0.8em;
}

.umbraco-forms-field.longanswer label:not(.checkboxLabel) {
  display: block;
  font-weight: 700;
  margin-bottom: 7px;
}
.umbraco-forms-field.longanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.longanswer select,
.umbraco-forms-field.longanswer textarea {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 120px;
  min-height: 48px;
  margin: 0;
  border: 1px solid #a9a9ad;
  padding: 6px 8px 6px;
  border-radius: 0;
  background: #fff;
  color: #3f3f3f;
  text-align: left;
}

form .error,
.formWrap .error {
  display: block;
  margin: 4px 0;
  padding: 2px 4px;
  text-align: center;
  background-color: #b10606;
  color: white;
}

@media (max-width: 600px) {
  .styledFieldset,
.umbraco-forms-fieldset {
    padding: 10px;
  }
  .styledFieldset .fieldsetGrid,
.umbraco-forms-fieldset .fieldsetGrid {
    grid-template-columns: none;
  }
}
@media (max-width: 480px) {
  .controlWrapper label {
    font-size: 1em;
    font-weight: 700;
    white-space: normal;
  }
}
@media (max-width: 400px) {
  .controlWrapper label {
    font-size: 0.9em;
  }
}
.buffer {
  overflow: auto;
  min-height: calc(100vh - 40px);
}

footer {
  position: relative;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background: #000;
}
footer .adgLogoWrap a {
  display: block;
  background: #000 !important;
}
footer .adgLogoWrap .adgLogo {
  height: 30px;
  width: auto;
}

.socialMediaTitle {
  margin-bottom: 15px;
  font-weight: 400;
}

.socialMediaLogins {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}
.socialMediaLogins .facebookLogin {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #1877f2;
  color: white;
}
.socialMediaLogins .facebookLogin:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  .socialMediaLogins .facebookLogin:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #1877f2;
    color: white;
    cursor: pointer;
  }
  .socialMediaLogins .facebookLogin:hover:after {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
[data-browser=ie] .socialMediaLogins .facebookLogin:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
}
[data-browser=ie] .socialMediaLogins .facebookLogin:hover:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.ie .socialMediaLogins .facebookLogin:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
}
.ie .socialMediaLogins .facebookLogin:hover:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.socialMediaLogins .facebookLogin.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
}
.socialMediaLogins .facebookLogin.focus-visible:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.socialMediaLogins .facebookLogin:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
}
.socialMediaLogins .facebookLogin:focus-visible:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.socialMediaLogins .facebookLogin[disabled], .socialMediaLogins .facebookLogin[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
.socialMediaLogins .googleLogin {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #ebebec;
  color: black;
}
.socialMediaLogins .googleLogin:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  .socialMediaLogins .googleLogin:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #ebebec;
    color: black;
    cursor: pointer;
  }
  .socialMediaLogins .googleLogin:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] .socialMediaLogins .googleLogin:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #ebebec;
  color: black;
  cursor: pointer;
}
[data-browser=ie] .socialMediaLogins .googleLogin:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie .socialMediaLogins .googleLogin:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #ebebec;
  color: black;
  cursor: pointer;
}
.ie .socialMediaLogins .googleLogin:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.socialMediaLogins .googleLogin.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #ebebec;
  color: black;
  cursor: pointer;
}
.socialMediaLogins .googleLogin.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.socialMediaLogins .googleLogin:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #ebebec;
  color: black;
  cursor: pointer;
}
.socialMediaLogins .googleLogin:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.socialMediaLogins .googleLogin[disabled], .socialMediaLogins .googleLogin[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
.socialMediaLogins .twitterLogin {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #14171a;
  color: white;
}
.socialMediaLogins .twitterLogin:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  .socialMediaLogins .twitterLogin:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #14171a;
    color: white;
    cursor: pointer;
  }
  .socialMediaLogins .twitterLogin:hover:after {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
[data-browser=ie] .socialMediaLogins .twitterLogin:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #14171a;
  color: white;
  cursor: pointer;
}
[data-browser=ie] .socialMediaLogins .twitterLogin:hover:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.ie .socialMediaLogins .twitterLogin:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #14171a;
  color: white;
  cursor: pointer;
}
.ie .socialMediaLogins .twitterLogin:hover:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.socialMediaLogins .twitterLogin.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #14171a;
  color: white;
  cursor: pointer;
}
.socialMediaLogins .twitterLogin.focus-visible:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.socialMediaLogins .twitterLogin:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #14171a;
  color: white;
  cursor: pointer;
}
.socialMediaLogins .twitterLogin:focus-visible:after {
  border-color: rgba(255, 255, 255, 0.5);
}
.socialMediaLogins .twitterLogin[disabled], .socialMediaLogins .twitterLogin[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
.socialMediaLogins .twitterLogin .text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.socialMediaLogins > a {
  text-align: center;
  padding: 1em 3em !important;
}
.socialMediaLogins > a .svgIcon {
  width: 25px;
  height: auto;
  margin-right: 10px;
}
.socialMediaLogins > a .text {
  font-weight: bold;
  font-size: 1.1em;
}

.fieldWrapper.studentName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

header {
  position: relative;
  z-index: 120;
  display: flex;
  height: 100px;
}
header .branding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: 18px 30px 18px 30px;
}
header .branding h1 {
  margin: 0;
}
header .logoWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 300px;
  height: 100%;
}
header .logo {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
header .orientationTitle {
  line-height: 1.1em;
  font-weight: 400;
  color: #4b2e83;
}

#membership {
  position: relative;
  z-index: 5;
  padding-bottom: 120px;
}
#membership .titleArea {
  position: relative;
  width: 100%;
  min-height: 200px;
  padding: 80px 20px 80px;
  border-radius: 0;
  text-align: center;
  font-size: 1.68em;
  background-color: #4b2e83;
  color: white;
}
#membership .titleArea:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: repeating-linear-gradient(110deg, transparent, transparent 6px, #e8e3d3 6px, #e8e3d3 8px);
}
#membership .titleArea.accountProfiler {
  font-size: 1.5em;
}
#membership .titleArea h2 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 900;
}
#membership .titleArea p {
  margin: 0;
}
#membership .errorWrap {
  position: relative;
  top: -45px;
  z-index: 5;
  width: 800px;
  max-width: calc(100% - 20px);
  margin: 10px auto 20px;
}
#membership .errorMessage {
  text-align: center;
  background: #35215d;
  box-shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036), 0 29px 62px rgba(0, 0, 0, 0.05);
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
  padding: 5px 10px;
}
#membership .loginNav {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #ebebec;
  padding-top: 10px;
  text-align: center;
  font-size: 0.9em;
}
#membership .loginNav > * {
  margin: 0 5px;
}
#membership .formWrap {
  position: relative;
  z-index: 5;
  top: -45px;
  width: 800px;
  max-width: calc(100% - 30px);
  margin: 10px auto 20px;
  padding: 25px 40px;
  border-radius: 0;
  box-shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036), 0 29px 62px rgba(0, 0, 0, 0.05);
  text-align: center;
  background-color: #fff;
  color: black;
}
#membership .formWrap input[type=radio] {
  position: relative;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
}
@supports (appearance: none) {
  #membership .formWrap input[type=radio] {
    appearance: none;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
  }
  #membership .formWrap input[type=radio]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-style: solid;
    background: #fff;
    border-width: 2px;
    border-color: rgba(63, 63, 63, 0.7);
    border-radius: 50%;
    transition: all 200ms;
  }
  #membership .formWrap input[type=radio]:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    transition: all 200ms;
  }
  #membership .formWrap input[type=radio]:checked:before {
    border-color: #4b2e83;
    background-color: #4b2e83;
  }
  #membership .formWrap input[type=radio]:checked:after {
    opacity: 1;
    background-color: white;
    width: 45%;
    height: 45%;
  }
  #membership .formWrap input[type=radio][disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  #membership .formWrap input[type=radio][disabled]:before {
    background-color: #fefefe;
  }
}
#membership .formWrap input[type=checkbox] {
  position: relative;
  margin: 0;
  cursor: pointer;
}
@supports (appearance: none) {
  #membership .formWrap input[type=checkbox] {
    appearance: none;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
  }
  #membership .formWrap input[type=checkbox]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    border-color: #3f3f3f;
    transition: all 200ms;
  }
  #membership .formWrap input[type=checkbox]:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: transparent url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9 IjAgMCAzMTYuMzggMjUwLjYiPjxwYXRoIGQ9Ik03MC40IDIxOS44YzQuNTUgNC44 NyA5LjIyIDkuNjcgMTQuMTIgMTQuMTkgMy43NSAzLjQ3IDcuNzEgNi44NSAxMS45 MyA5Ljc0IDUuNSAzLjc4IDExLjkyIDcuMDggMTguNzggNi44NSA2LjY2LS4yMyAx Mi43Ny0zLjczIDE3Ljk3LTcuNjIgOC42LTYuNDIgMTYuNjctMTUuMTcgMjMuODQt MjMuMTRMMzA1LjY4IDU0LjY5YzcuMi04IDExLjc5LTE4LjI1IDEwLjQ3LTI5LjE3 LTEuMDQtOC41OS01LjcxLTE2LjU1LTEzLjAxLTIxLjI5LTUuNjUtMy42Ny0xMi4y Ni00Ljk4LTE4LjktMy44Mi04LjkgMS41Ni0xNi41NCA3LjIyLTIyLjQ4IDEzLjc4 LTQ4LjM0IDUzLjMyLTk2LjU5IDEwNi43MS0xNDQuODUgMTYwLjExbC0zLjE5IDMu NTMtNjAuNC02Ni42M2MtOC40Mi05LjI5LTE5LjctMTMuOTQtMzEuOTItOS41Ny00 LjcyIDEuNjktOC45OSA0LjU4LTEyLjQ1IDguMTktMy44NiA0LjA0LTYuNzggOS4w Ni04LjEzIDE0LjQ5LTIuMzEgOS4yNy4zIDE4LjA3IDYuNjIgMjUuMTNsNjIuOTUg NzAuMzVaIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQiLz48L3N2Zz4K") no-repeat;
    background-size: 70%;
    background-position: 50% 50%;
    transition: all 200ms;
    opacity: 0;
    filter: invert(1);
  }
  #membership .formWrap input[type=checkbox]:checked:before {
    border-color: #4b2e83;
    background-color: #4b2e83;
  }
  #membership .formWrap input[type=checkbox]:checked:after {
    opacity: 1;
  }
  #membership .formWrap input[type=checkbox][disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  #membership .formWrap input[type=checkbox][disabled]:before {
    background-color: rgba(63, 63, 63, 0.1);
  }
}
#membership .formWrap input[type=checkbox] ~ label,
#membership .formWrap input[type=radio] ~ label {
  padding-left: 0.35em;
}
#membership .formWrap.login .horzDivider {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
#membership .formWrap.login .horzDivider .line {
  height: 2px;
  flex-grow: 1;
  background: #ebebec;
}
#membership .formWrap.login .horzDivider .or {
  text-transform: uppercase;
  color: #3f3f3f;
  margin-inline: 15px;
}
#membership .formWrap.login .loginWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 8px;
}
#membership .formWrap.login .returningWrapper {
  width: calc(60% - 20px);
  text-align: center;
}
#membership .formWrap.login .newAccountWrapper {
  width: 40%;
}
#membership .formWrap.login .newAccountWrapper > a:first-child {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #4b2e83;
  color: white;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
#membership .formWrap.login .newAccountWrapper > a:first-child:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #b7a57a;
    color: black;
    cursor: pointer;
  }
  #membership .formWrap.login .newAccountWrapper > a:first-child:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-browser=ie] #membership .formWrap.login .newAccountWrapper > a:first-child:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.ie #membership .formWrap.login .newAccountWrapper > a:first-child:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership .formWrap.login .newAccountWrapper > a:first-child.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership .formWrap.login .newAccountWrapper > a:first-child:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership .formWrap.login .newAccountWrapper > a:first-child[disabled], #membership .formWrap.login .newAccountWrapper > a:first-child[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .formWrap.login .newAccountWrapper > a:first-child[data-type=Student] .icon:before {
  text-transform: lowercase;
  content: "z";
}
#membership .formWrap.login .newAccountWrapper > a:first-child .icon {
  font-size: 2.5em;
  margin-bottom: 10px;
}
#membership .formWrap.login .newAccountWrapper > a:first-child .text {
  font-weight: bold;
  font-size: 1.15em;
}
#membership .formWrap.landingPage {
  padding-top: 40px;
  padding-bottom: 40px;
}
#membership .formWrap.landingPage .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
#membership .formWrap.landingPage a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #4b2e83;
  color: white;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 6px;
  font-size: 1.4em;
}
#membership .formWrap.landingPage a:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.landingPage a:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #b7a57a;
    color: black;
    cursor: pointer;
  }
  #membership .formWrap.landingPage a:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] #membership .formWrap.landingPage a:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-browser=ie] #membership .formWrap.landingPage a:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie #membership .formWrap.landingPage a:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.ie #membership .formWrap.landingPage a:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership .formWrap.landingPage a.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#membership .formWrap.landingPage a.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership .formWrap.landingPage a:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#membership .formWrap.landingPage a:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership .formWrap.landingPage a[disabled], #membership .formWrap.landingPage a[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.landingPage a:hover .icon {
    border-radius: 50%;
    background-color: #4b2e83;
    color: white;
  }
}
[data-browser=ie] #membership .formWrap.landingPage a:hover .icon {
  border-radius: 50%;
  background-color: #4b2e83;
  color: white;
}
.ie #membership .formWrap.landingPage a:hover .icon {
  border-radius: 50%;
  background-color: #4b2e83;
  color: white;
}
#membership .formWrap.landingPage .text {
  font-weight: bold;
}
#membership .formWrap.landingPage .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-bottom: 15px;
  font-family: "uw-bothell";
  font-size: 2.1em;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 200ms;
  background-color: #b7a57a;
  color: black;
}
#membership .formWrap.landingPage .guestLoginBtn {
  transform-origin: 25% 50%;
}
#membership .formWrap.landingPage .guestLoginBtn .icon:before {
  content: "k";
}
#membership .formWrap.landingPage .studentLoginBtn {
  transform-origin: 75% 50%;
}
#membership .formWrap.landingPage .studentLoginBtn .icon:before {
  content: "z";
}
#membership .formWrap.accountProfiler {
  text-align: left;
}
#membership .formWrap.accountProfiler .instruction {
  margin-bottom: 15px;
  text-align: center;
}
#membership .formWrap.accountProfiler select {
  margin: 0 0 0 5px;
  border: 5px solid #ebebec;
  background: #ebebec;
  font-weight: 700;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.accountProfiler select:hover {
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.accountProfiler select:hover {
  cursor: pointer;
}
.ie #membership .formWrap.accountProfiler select:hover {
  cursor: pointer;
}
#membership .formWrap.accountProfiler .buttonHolder {
  margin: 20px 0 20px;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn {
  border: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  overflow: visible;
  font-size: 0.8em;
  border-bottom: 1px solid #4b2e83;
  cursor: pointer;
  color: #4b2e83;
  background-color: white;
  transition: 200ms;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
    border-color: #b7a57a;
    color: #b7a57a;
    background-color: black;
  }
}
[data-browser=ie] #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
  border-color: #b7a57a;
  color: #b7a57a;
  background-color: black;
}
.ie #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
  border-color: #b7a57a;
  color: #b7a57a;
  background-color: black;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn.focus-visible {
  border-color: #b7a57a;
  color: #b7a57a;
  background-color: black;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn:focus-visible {
  border-color: #b7a57a;
  color: #b7a57a;
  background-color: black;
}
#membership .buttonHolder {
  text-align: center;
}
#membership button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #4b2e83;
  color: white;
  margin: 0 auto;
}
#membership button:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  #membership button:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #b7a57a;
    color: black;
    cursor: pointer;
  }
  #membership button:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] #membership button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-browser=ie] #membership button:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie #membership button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.ie #membership button:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership button.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#membership button.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership button:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#membership button:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#membership button[disabled], #membership button[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
#membership .styledFieldset {
  margin-bottom: 20px;
}
#membership .styledFieldset .groupLegend {
  margin-bottom: 10px;
}
#membership .styledFieldset .controlWrapper select {
  padding-right: 30px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
#membership .styledFieldset .controlWrapper > span.dropDownArrow {
  display: inline;
  position: relative;
  height: 30px;
  width: 30px;
  left: -25px;
  pointer-events: none;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .styledFieldset .controlWrapper > span.dropDownArrow:hover {
    cursor: pointer;
  }
}
[data-browser=ie] #membership .styledFieldset .controlWrapper > span.dropDownArrow:hover {
  cursor: pointer;
}
.ie #membership .styledFieldset .controlWrapper > span.dropDownArrow:hover {
  cursor: pointer;
}
#membership .styledFieldset .controlWrapper > span.dropDownArrow:after {
  content: "w";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-family: "uw-bothell";
  font-size: 1em;
  color: #3f3f3f;
}

.simpleDialog .closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #4b2e83;
  color: white;
}
.simpleDialog .closeDialog:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  .simpleDialog .closeDialog:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #b7a57a;
    color: black;
    cursor: pointer;
  }
  .simpleDialog .closeDialog:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] .simpleDialog .closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-browser=ie] .simpleDialog .closeDialog:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie .simpleDialog .closeDialog:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.ie .simpleDialog .closeDialog:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.simpleDialog .closeDialog.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.simpleDialog .closeDialog.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.simpleDialog .closeDialog:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.simpleDialog .closeDialog:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.simpleDialog .closeDialog[disabled], .simpleDialog .closeDialog[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}
.simpleDialog .closeDialog .icon {
  display: none;
}

#resetData button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.5em;
  border: 0;
  line-height: 1.1em;
  text-align: center;
  border-radius: 0;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  outline-offset: 0;
  text-decoration: none;
  transition: all 200ms;
  background-color: #4b2e83;
  color: white;
}
#resetData button:after {
  content: "";
  position: absolute;
  inset: 3px 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.5);
}
@media (hover: hover) and (any-pointer: fine) {
  #resetData button:hover {
    transform: scale(1.03);
    text-decoration: none;
    background-color: #b7a57a;
    color: black;
    cursor: pointer;
  }
  #resetData button:hover:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
}
[data-browser=ie] #resetData button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
[data-browser=ie] #resetData button:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
.ie #resetData button:hover {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
.ie #resetData button:hover:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#resetData button.focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#resetData button.focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#resetData button:focus-visible {
  transform: scale(1.03);
  text-decoration: none;
  background-color: #b7a57a;
  color: black;
  cursor: pointer;
}
#resetData button:focus-visible:after {
  border-color: rgba(0, 0, 0, 0.5);
}
#resetData button[disabled], #resetData button[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 800px) {
  header {
    height: auto;
  }
  header .branding {
    flex-direction: column;
  }
  header .branding .logoWrap {
    justify-content: center;
    width: 100%;
    max-width: 280px;
    flex: auto;
    margin-bottom: 10px;
  }
  header .branding .orientationTitle {
    text-align: center;
  }
  .login > .socialMediaLogins {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  #membership .titleArea {
    font-size: 1.5em;
  }
  #membership .formWrap.login .loginWrapper {
    flex-direction: column;
    gap: 15px;
  }
  #membership .formWrap.login .newAccountWrapper {
    width: auto;
    margin: auto;
  }
  #membership .formWrap.login .returningWrapper {
    width: auto;
  }
  #membership .formWrap.landingPage .buttons a {
    flex-grow: 1;
  }
}
@media (max-width: 600px) {
  #membership .titleArea {
    font-size: 1.35em;
    padding: 75px 20px 70px;
    min-height: auto;
  }
  #membership .formWrap {
    padding: 25px;
  }
  #membership .formWrap.login .newAccountWrapper {
    width: auto;
  }
  #membership .formWrap.landingPage .buttons {
    flex-direction: column;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 100%;
  }
  #membership .formWrap.landingPage .buttons a:first-child {
    margin-bottom: 15px;
  }
  #resetData button {
    width: 100%;
    margin-bottom: 8px;
  }
}
@media (max-width: 480px) {
  #membership .titleArea.accountProfiler {
    font-size: 1.125em;
  }
  header .orientationTitle {
    font-size: 1.8em;
  }
}
@media (max-width: 400px) {
  #membership .formWrap {
    padding: 16px;
  }
  #membership .titleArea {
    padding: 70px 15px 65px;
    font-size: 1.1em;
  }
  #membership .formWrap.landingPage {
    padding: 20px;
  }
}
.rte h4,
.rte h5 {
  margin: 0.8em 0 0.5em;
}
.rte > *:first-child {
  margin-top: 0 !important;
}
.rte > *:last-child {
  margin-bottom: 0 !important;
}

.rte {
  margin-bottom: 20px;
}
.rte p {
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
}
.rte h3.heading {
  display: inline-block;
  position: relative;
  margin: 0.75em 0 0.4em;
  padding-bottom: 14px;
  font-size: 1.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-family: "Encode Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #4b2e83;
  background-color: white;
}
.rte h3.heading:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35%;
  height: 7px;
  background: #4b2e83;
}
.rte h3.heading:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(35% - 5px);
  width: 10px;
  height: 7px;
  transform: skewX(-14deg);
  background: #4b2e83;
}
.rte .headingList,
.rte .subheadingList {
  margin: 0.75em 0 0.4em;
  font-weight: 400;
  font-family: "Uni Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #4b2e83;
  background-color: white;
}
.rte h3.headingList {
  font-size: 1.5em;
}
.rte h4.subheadingList {
  font-size: 1.15em;
}
.rte h4.subheading {
  margin: 0.75em 0 0.4em;
  font-size: 1.35em;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #3f3f3f;
  background-color: white;
  border-radius: 8px;
}
.rte .largeText {
  position: relative;
  margin-bottom: 20px;
  display: block;
  font-size: 1.3em;
  line-height: 1.5em;
  font-family: "Encode Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
.rte .highlight {
  background-color: #e8e3d3;
  color: black;
}
.rte ul {
  padding: 0;
}
.rte ul > li {
  position: relative;
  margin-left: 40px;
  margin-bottom: 8px;
  list-style-type: none;
  line-height: 1.6em;
}
.rte ul > li:after {
  content: "";
  position: absolute;
  top: 8px;
  left: -16px;
  width: 7px;
  height: 8px;
  background: #4b2e83;
  border-radius: 2px;
}
.rte ol {
  padding: 0;
  list-style-type: none;
  counter-reset: counter;
}
.rte ol > li {
  position: relative;
  counter-increment: counter;
  margin-left: 40px;
  margin-bottom: 5px;
}
.rte ol > li:before {
  content: counter(counter) ". ";
  position: absolute;
  right: calc(100% + 8px);
  color: #4b2e83;
  font-weight: bold;
}
.rte a {
  text-decoration: none;
  color: #4b2e83;
  background-color: white;
  border-bottom: 2px solid #4b2e83;
  overflow-wrap: break-word;
  /*
  &:before {
    @include iconFont;
    content: 'd';
    padding-right: 5px;
    font-size: 0.8em;
  }

  &[href^="mailto:"] {
    &:before {
      @include iconFont;
      content: 'M';
      padding-right: 5px;
    }
  }

  &[href*='.pdf'] {
    &:before {
      @include iconFont;
      content: "p";
      padding-right: 5px;
    }
  }*/
}
@media (hover: hover) and (any-pointer: fine) {
  .rte a:hover {
    background-color: #4b2e83;
    color: white;
  }
}
[data-browser=ie] .rte a:hover {
  background-color: #4b2e83;
  color: white;
}
.ie .rte a:hover {
  background-color: #4b2e83;
  color: white;
}
.rte a.focus-visible {
  background-color: #4b2e83;
  color: white;
}
.rte a:focus-visible {
  background-color: #4b2e83;
  color: white;
}
.rte.calloutSubtle {
  margin: 0.75em 0 0.9em;
  padding: 10px 15px;
  border-radius: 0;
  background-color: #ebebec;
  color: black;
}
.rte.calloutSubtle a {
  background-color: #ebebec;
  color: black;
  border-bottom: 2px solid black;
}
@media (hover: hover) and (any-pointer: fine) {
  .rte.calloutSubtle a:hover {
    color: #3f3f3f;
    background-color: white;
    border-bottom-color: #3f3f3f;
  }
}
[data-browser=ie] .rte.calloutSubtle a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.ie .rte.calloutSubtle a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutSubtle a.focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutSubtle a:focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutSubtle > *:first-child {
  margin-top: 0 !important;
}
.rte.calloutSubtle > *:last-child {
  margin-bottom: 0 !important;
}
.rte.calloutBold {
  margin: 0.75em 0 0.9em;
  padding: 10px 15px;
  border-radius: 0;
  background-color: #4b2e83;
  color: white;
}
.rte.calloutBold a {
  background-color: #4b2e83;
  color: white;
  border-bottom: 2px solid white;
}
@media (hover: hover) and (any-pointer: fine) {
  .rte.calloutBold a:hover {
    color: #3f3f3f;
    background-color: white;
    border-bottom-color: #3f3f3f;
  }
}
[data-browser=ie] .rte.calloutBold a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.ie .rte.calloutBold a:hover {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutBold a.focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutBold a:focus-visible {
  color: #3f3f3f;
  background-color: white;
  border-bottom-color: #3f3f3f;
}
.rte.calloutBold > *:first-child {
  margin-top: 0 !important;
}
.rte.calloutBold > *:last-child {
  margin-bottom: 0 !important;
}

#membership .formWrap.maintenance .formArea {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#membership .formWrap.maintenance .formArea .icon {
  color: #4b2e83;
}
#membership .formWrap.maintenance .formArea .rte h3.heading:before {
  left: 50%;
  transform: translateX(-50%);
}
#membership .formWrap.maintenance .formArea .rte h3.heading:after {
  display: none;
}

.membershipPage .errorPage .errorWrap {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #4b2e83;
  margin-top: 10px;
  box-shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036), 0 29px 62px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.membershipPage .errorPage .icon {
  padding: 30px;
  font-size: 6em;
  border-bottom: 2px solid #4b2e83;
  width: 50%;
  display: flex;
  justify-content: center;
}
.membershipPage .errorPage .rte {
  text-align: center;
  padding: 40px 30px 20px 30px;
  width: 50%;
}
.membershipPage .errorPage .rte h3.heading {
  background: transparent;
  color: black;
  text-transform: none;
  text-align: left;
}
@media (max-width: 800px) {
  .membershipPage .errorPage .errorWrap {
    padding: 20px 30px;
  }
  .membershipPage .errorPage .icon {
    width: 300px;
  }
  .membershipPage .errorPage .rte {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .membershipPage .errorPage .errorWrap {
    padding: 20px;
  }
  .membershipPage .errorPage .icon {
    width: 100%;
  }
  .membershipPage .errorPage .rte {
    padding: 30px 0 20px;
  }
}

details {
  transition: all 500ms;
}

details > summary::marker,
details > summary::-webkit-details-marker {
  display: none;
}

.accordionModule .accordionTitle {
  cursor: pointer;
  list-style: none;
}

.accordionModule {
  position: relative;
  margin: 10px 0;
}
.accordionModule[open] > .accordionTitle {
  width: 100%;
}
.accordionModule[open] > .accordionTitle:before {
  left: 40px;
  opacity: 0;
}
.accordionModule[open] > .accordionTitle:after {
  left: calc(100% - 40px);
  opacity: 0;
}
.accordionModule[open] > .accordionTitle .icon {
  transform: translateY(-50%) rotate(180deg);
  transform-origin: center;
}
.accordionModule .accordionTitle {
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 20px 20px 40px;
  background-color: #4b2e83;
  color: white;
  transition: all 200ms;
}
.accordionModule .accordionTitle:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: calc(100% - 1px);
  background: #4b2e83;
  transform: skewX(10deg);
  transform-origin: 50% 100%;
  transition: all 200ms;
}
.accordionModule .accordionTitle:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%;
  background: #4b2e83;
  transform: skewX(-10deg);
  transform-origin: 50% 0%;
  transition: all 200ms;
}
@media (hover: hover) and (any-pointer: fine) {
  .accordionModule .accordionTitle:hover {
    background-color: #e8e3d3;
    color: black;
  }
  .accordionModule .accordionTitle:hover:before, .accordionModule .accordionTitle:hover:after {
    background: #e8e3d3;
  }
  .accordionModule .accordionTitle:hover .icon {
    color: black;
  }
}
[data-browser=ie] .accordionModule .accordionTitle:hover {
  background-color: #e8e3d3;
  color: black;
}
[data-browser=ie] .accordionModule .accordionTitle:hover:before, [data-browser=ie] .accordionModule .accordionTitle:hover:after {
  background: #e8e3d3;
}
[data-browser=ie] .accordionModule .accordionTitle:hover .icon {
  color: black;
}
.ie .accordionModule .accordionTitle:hover {
  background-color: #e8e3d3;
  color: black;
}
.ie .accordionModule .accordionTitle:hover:before, .ie .accordionModule .accordionTitle:hover:after {
  background: #e8e3d3;
}
.ie .accordionModule .accordionTitle:hover .icon {
  color: black;
}
.accordionModule .accordionTitle.focus-visible {
  background-color: #e8e3d3;
  color: black;
}
.accordionModule .accordionTitle.focus-visible:before, .accordionModule .accordionTitle.focus-visible:after {
  background: #e8e3d3;
}
.accordionModule .accordionTitle.focus-visible .icon {
  color: black;
}
.accordionModule .accordionTitle:focus-visible {
  background-color: #e8e3d3;
  color: black;
}
.accordionModule .accordionTitle:focus-visible:before, .accordionModule .accordionTitle:focus-visible:after {
  background: #e8e3d3;
}
.accordionModule .accordionTitle:focus-visible .icon {
  color: black;
}
.accordionModule .accordionTitle .icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: rotate(0) translateY(-50%);
  transform-origin: 4px 50%;
  transition: 0.2s transform;
}
.accordionModule .accordionTitle .icon:before {
  content: "w";
  font-family: "uw-bothell";
}
.accordionModule .accordionWrapper {
  padding: 20px 20px;
  background-color: #fff;
  color: black;
}