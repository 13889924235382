@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.fieldWrapper.studentName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.longanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  position: relative;
  max-width: 500px;

  label:not(.checkboxLabel) {
    line-height: 1;
    white-space: normal;
  }

  input:not([type="checkbox"]):not([type="radio"]),
  select,
  textarea {
    appearance: none;
    position: relative;
    white-space: normal;
    line-height: 1;
    min-width: 100%;
    max-width: 100%;
    max-height: 120px;
  }

  select {
    &::-ms-expand {
      display: none;
    }
  }
}

.controlWrapper,
.adg-umbraco-forms-controlwrapper {
  border-bottom: 1px solid $lightGrey;
  margin-bottom: 15px;
  padding-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  .control {
    display: flex;
    align-items: center;
  }

  label {
    font-size: 1.2em;
    white-space: nowrap;
    cursor: pointer;
  }

  .description {
    margin-top: 6px;
    font-size: 0.84em;
    color: $darkGrey;

    > *:first-child {
      margin-top: 0 !important;
    }
    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

//These style is just used for the Honeypot feature. These styles keep the field from being seen by humans and they should not be changed or removed.
.fieldWrapper.studentName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

// END BASE STYLES

.styledFieldset,
.umbraco-forms-fieldset {
  position: relative;
  border: 1px solid $lightGrey;
  padding: 20px;
  background-color: rgba($lightGrey, 0.4);
  border-radius: $radius;

  legend,
  .groupLegend {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 0;
    @include bgWithContrast($primary);
  }

  .groupLegend {
    display: inline-block;
    margin-bottom: 9px;
  }

  .fieldsetGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .fieldWrapper,
    .controlWrapper {
      margin: 0;
    }
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  margin: 10px auto;

  label:not(.checkboxLabel) {
    position: absolute;
    top: 10px;
    left: 9px;
    z-index: 2;
    display: block;
    margin: 0 0 5px;
    border: 0;
    padding: 0;
    font-size: 0.8em;
    font-family: $primaryFont;
    color: $darkGrey;
  }

  input:not([type="checkbox"]):not([type="radio"]),
  select,
  textarea {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 120px;
    min-height: 48px;
    margin: 0;
    border: 1px solid $grey;
    padding: 23px 8px 6px;
    border-radius: $radius;
    background: $light;
    color: $basic;
    text-align: left;
  }

  select {
    padding-top: 24px;
    padding-right: 2.3em;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 1em) center;
    background-size: 0.8em;
  }
}

.umbraco-forms-field.longanswer {
  label:not(.checkboxLabel) {
    display: block;
    font-weight: 700;
    margin-bottom: 7px;
  }
  input:not([type="checkbox"]):not([type="radio"]),
  select,
  textarea {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 120px;
    min-height: 48px;
    margin: 0;
    border: 1px solid $grey;
    padding: 6px 8px 6px;
    border-radius: $radius;
    background: $light;
    color: $basic;
    text-align: left;
  }
}

form,
.formWrap {
  .error {
    display: block;
    margin: 4px 0;
    padding: 2px 4px;
    text-align: center;
    @include bgWithContrast($wrong);
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $xs) {
  .styledFieldset,
  .umbraco-forms-fieldset {
    padding: 10px;
    .fieldsetGrid {
      grid-template-columns: none;
    }
  }
}

@media (max-width: $xxs) {
  .controlWrapper {
    label {
      font-size: 1em;
      font-weight: 700;
      white-space: normal;
    }
  }
}

@media (max-width: $tiny) {
  .controlWrapper {
    label {
      font-size: 0.9em;
    }
  }
}
