@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.rte {
  h4,
  h5 {
    margin: 0.8em 0 0.5em;
  }
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

// END BASE STYLES //

.rte {
  margin-bottom: 20px;
  p {
    font-family: $basicFont;
    font-size: 1em;
  }

  h3.heading {
    display: inline-block;
    position: relative;
    margin: 0.75em 0 0.4em;
    padding-bottom: 14px;
    font-size: 1.75em;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    font-family: $primaryFont;
    @include colorWithContrast($primary);
    //@include colorWithContrast($primary, $bgLight: $lightGrey);

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 35%;
      height: 7px;
      background: $primary;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(35% - 5px);
      width: 10px;
      height: 7px;
      transform: skewX(-14deg);
      background: $primary;
    }
  }

  .headingList,
  .subheadingList {
    margin: 0.75em 0 0.4em;
    font-weight: 400;
    font-family: $secondaryFont;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    @include colorWithContrast($primary);
    //@include colorWithContrast($primary, $bgLight: $lightGrey);
  }

  h3.headingList {
    font-size: 1.5em;
  }

  h4.subheadingList {
    font-size: 1.15em;
  }

  h4.subheading {
    margin: 0.75em 0 0.4em;
    font-size: 1.35em;
    font-weight: 700;
    letter-spacing: 0.02em;
    @include colorWithContrast($basic);
    //@include colorWithContrast($basic, $bgLight: $lightGrey);
    border-radius: 8px;
  }

  .largeText {
    position: relative;
    //padding-top: 13px;
    margin-bottom: 20px;
    display: block;
    font-size: 1.3em;
    line-height: 1.5em;
    font-family: $primaryFont;

    // &:before {
    //   content: "";
    //   position: absolute;
    //   left: -20px;
    //   bottom: -5px;
    //   width: 3px;
    //   height: 0;
    //   background: $primary;
    //   transition: height $fast;
    //   transition-delay: $verySlow;

    //   .current & {
    //     height: calc(100% + 5px);
    //   }
    // }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   left: -20px;
    //   top: 0;
    //   width: 0;
    //   height: 3px;
    //   background: $primary;
    //   transition: width $fast;
    //   transition-delay: $verySlow + 0.3s;

    //   .current & {
    //     width: calc(100% + 20px);
    //   }
    // }
  }

  .highlight {
    @include bgWithContrast($accent);
  }

  ul {
    padding: 0;

    > li {
      position: relative;
      margin-left: 40px;
      margin-bottom: 8px;
      list-style-type: none;
      line-height: 1.6em;

      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 7px;
      //   left: -15px;
      //   width: 4px;
      //   height: 8px;
      //   background: $primary;
      // }
      &:after {
        content: '';
        position: absolute;
        top: 8px;
        left: -16px;
        width: 7px;
        height: 8px;
        background: $primary;
        border-radius: 2px;
      }
    }
  }

  ol {
    padding: 0;
    list-style-type: none;
    counter-reset: counter;

    > li {
      position: relative;
      counter-increment: counter;
      margin-left: 40px;
      margin-bottom: 5px;

      &:before {
        content: counter(counter) ". ";
        position: absolute;
        right: calc(100% + 8px);
        color: $primary;
        font-weight: bold;
      }
    }
  }

  a {
    text-decoration: none;
    @include colorWithContrast($primary);
    //@include colorWithContrast($primary, $bgLight: $lightGrey);
    border-bottom: 2px solid $primary;
    overflow-wrap: break-word;

    @include hoverAndFocus {
      @include bgWithContrast($primary);
    }

    /*
    &:before {
      @include iconFont;
      content: 'd';
      padding-right: 5px;
      font-size: 0.8em;
    }
    
    &[href^="mailto:"] {
      &:before {
        @include iconFont;
        content: 'M';
        padding-right: 5px;
      }
    }
    
    &[href*='.pdf'] {
      &:before {
        @include iconFont;
        content: "p";
        padding-right: 5px;
      }
    }*/
  }

  &.calloutSubtle {
    margin: 0.75em 0 0.9em;
    padding: 10px 15px;
    border-radius: $radius;
    @include bgWithContrast($lightGrey);

    a {
      @include bgWithContrast($lightGrey);
      border-bottom: 2px solid contrast($lightGrey);

      @include hoverAndFocus {
        @include colorWithContrast($basic);
        border-bottom-color: $basic;
      }
    }

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }

  &.calloutBold {
    margin: 0.75em 0 0.9em;
    padding: 10px 15px;
    border-radius: $radius;
    @include bgWithContrast($primary);

    a {
      @include bgWithContrast($primary);
      border-bottom: 2px solid contrast($primary);

      @include hoverAndFocus {
        @include colorWithContrast($basic);
        border-bottom-color: $basic;
      }
    }

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

