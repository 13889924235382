// Colors
$primary: #4b2e83; // Purple
$secondary: #b7a57a; // Gold
$accent: #e8e3d3; // Light Gold
$alt: #85754d; // Dark Gold

$primaryHover: lighten($primary, 15%);

$light: #fff; // White
$lightGrey: #ebebec; // Light Grey
$grey: #a9a9ad; // Grey (50~%, unsuitable for use with text)
$darkGrey: #54545a; // Dark Grey (25~%)
$black: #000000; // Black
$basic: #3f3f3f; // Dark Grey (10~%)

$link: $primary;

//Hover color can be a 3rd color is school has one
$hover: darken($primary, 10%);
//ADA hover needs to be a color that contrasts with
//the primary color but also is dark enough to see white against.
$ADAhover: $basic;

$wrong: #b10606; //Red
$correct: #0e8113; //Green

// Fonts
$primaryFont: "Encode Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$secondaryFont: "Uni Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$basicFont: "Open Sans", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$genericFont: "Arial", sans-serif;

// Widths
$tiny: 400px;
$xxs: 480px;
$xs: 600px;
$sm: 800px;
$md: 1050px;
$lg: 1280px;
$xl: 1500px;

// Animation Speeds - these also need to be changed in animation.ts if modified
$veryFast: 200ms;
$fast: 300ms;
$medium: 500ms;
$slow: 800ms;
$verySlow: 1000ms;
$glacial: 3000ms;


$iconFont: "uw-bothell";

// Misc.
$shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036),
  0 29px 62px rgba(0, 0, 0, 0.05);
$radius: 0;

// Elements
$progress: 20px;
$headerHeight: 100px;
$footerHeight: 45px;
$infoSize: 50px;
$smHeader: 60px;
$maxWidth: 1600px;
//Based on the logo, adjust this max width for ADA responsive
$logoADAMax: 275px;
