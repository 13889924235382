@use "mixins" as *;
@use "variables" as *;

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  display: none !important;

  .bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; 
    @include bgPos();
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $lightGrey;
  }

  [data-is-loading="true"] & {
    opacity: 0;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
